<template>
  <div>
  <div class="layoutBorder">
    <div class="layoutHeader">
      <h4>Личный кабинет клиента ФИНМОЛЛ</h4>
    </div>
    <div class="layoutBody">
      <p class="desc">Для удобства своих клиентов  ООО МКК  «ФИНМОЛЛ» выпустило мобильное приложение «Личный кабинет
        клиента»</p>
      <br>
      <p class="desc">Пользоваться данным приложением удобно и безопасно, так как мы не собираем и никуда не передаем ваши личные
        данные. Подробнее о политике обработки персональных данных в компании ФИНМОЛЛ, на сайте компании и в МОБИЛЬНОМ ПРИЛОЖЕНИИ "КАБИНЕТ КЛИЕНТА ФИНМОЛЛ" можно прочитать по <a href="https://lk.msk.finmoll.com/api/assets/pdf/pdn_finmoll.pdf" target="_blank">ссылке</a></p>
      <br>
      <p class="desc">В приложении вы можете ознакомится с графиком платежей,  фактически поступившими платежами, внести оплату и получить помощь по интересующим Вас вопросам.</p>
    </div>
  </div>
  <info></info>
  </div>
</template>

<script>
  export default {
    name: "description",
    components:{
      info:() => import('@/views/public/info'),
    }
  }
</script>

<style scoped>

</style>
